<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="meetingId"
          label="Meeting ID"
          :rules="notEmpty"
          prepend-icon="mdi-pound" />
        <v-text-field
          v-model="token"
          label="Token"
          :rules="notEmpty"
          prepend-icon="mdi-account" />
        <v-select
          v-model="view"
          :items="selectItems"
          label="Ansicht"
          :rules="notEmpty"
          prepend-icon="mdi-view-dashboard"
        ></v-select>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="connect()"
        class="pa-6 mb-6 mr-6"
        dark>
        Verbinden
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'Connect',
  data: () => ({
    meetingId: '',
    token: '',
    view: '/view1',
    notEmpty: [
      v => !!v || 'Pflichtfeld'
    ]
  }),
  computed: {
    selectItems () {
      const views = this.$store.state.config.views
      const selectItems = []
      for (const i in views) {
        if (views[i].active && views[i].route !== '/') {
          selectItems.push({
            text: views[i].title,
            value: views[i].route
          })
        }
      }
      return selectItems
    }
  },
  methods: {
    setData (meetingId, token, view) {
      this.meetingId = meetingId
      this.token = token
      this.view = view || this.view
    },

    connect () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('initiateConnection', { meetingId: this.meetingId, token: this.token }).then(() => {
          localStorage.setItem('auth', JSON.stringify({ meetingId: this.meetingId, token: this.token, view: this.view }))
          this.$router.push(this.view)
        })
      }
    }
  },

  mounted () {
    const storage = localStorage.getItem('auth')
    const query = new URLSearchParams(window.location.search)
    if (query && (query.get('token') || query.get('meeting'))) {
      this.setData(query.get('meeting'), query.get('token'), query.get('view'))
    } else if (storage) {
      const credentials = JSON.parse(storage)
      this.setData(credentials.meetingId, credentials.token, credentials.view)
    }
  }
}
</script>
