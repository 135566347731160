import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/view1',
    name: 'Ansicht 1',
    component: () => import('../views/View1.vue')
  },
  {
    path: '/view2',
    name: 'Ansicht 2',
    component: () => import('../views/View2.vue')
  },
  {
    path: '/view3',
    name: 'Ansicht 3',
    component: () => import('../views/View3.vue')
  },
  {
    path: '/view4',
    name: 'Ansicht 4',
    component: () => import('../views/View4.vue')
  },
  {
    path: '/results',
    name: 'Abstimmungsergebnisse',
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/conference',
    name: 'Konferenz',
    component: () => import('../views/Conference.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
