<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menü
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <template v-for="item in $store.state.config.views">
          <v-list-item
            v-if="item.active"
            :key="item.title"
            :to="item.route"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-1">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $store.state.config.title }}</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navigation',

  data: () => ({
    drawer: false
  })
}
</script>

<style lang="scss">
   .v-app-bar {
      background-color: var(--background-color) !important;
    }

  .v-toolbar__title {
    font-family: var(--headline-font), "Roboto", sans-serif;
    text-transform: var(--headline-transform);
    font-weight: var(--headline-font-weight);
    font-style: var(--headline-font-style);
    font-size: 2rem;
    letter-spacing: 0.5px;
  }
</style>
