function getBaseUrl () {
  return 'https://@abstimmung.netzbegruenung.de/api/meeting/'
}

function getSupportInfo () {
  return {
    name: 'verdigado',
    mail: 'support@verdigado.com',
    mailSubject: 'API-Fehler Präsidiumsansicht'
  }
}

function getDefaultConfig () {
  return {
    title: 'Präsidiumsansicht',
    theme: 'bdk-22',
    language: 'DE',
    colors: {
      background: '#00694f',
      headline: '#ffffff',
      text: '#ffffff',
      hashtag: '#00a57e',
      bar: '#ffffff',
      button: '#00a57e'
    },
    fonts: {
      headlineFontFamily: 'BereitBold',
      bodyFontFamily: 'PTSans',
      headlineTransform: 'none',
      bodyTransform: 'none',
      headlineFontWeight: 'normal',
      bodyFontWeight: 'normal',
      headlineFontStyle: 'none',
      bodyFontStyle: 'none'
    },
    views: [
      {
        title: 'Neues Meeting',
        icon: 'mdi-plus',
        route: '/',
        active: true,
        type: 'connect'
      },
      {
        title: 'Übersicht',
        icon: 'mdi-view-dashboard',
        route: '/view1',
        active: true,
        type: 'view',
        cols: 2,
        elements: [
          ['delegates', 'currentPoll'],
          ['comingPolls'],
          []
        ]
      },
      {
        title: 'Tagesordnung',
        icon: 'mdi-format-list-bulleted',
        route: '/view2',
        active: true,
        type: 'view',
        cols: 1,
        elements: [
          ['agenda'],
          [],
          []
        ]
      },
      {
        title: 'Beiträge',
        icon: 'mdi-vote',
        route: '/view3',
        active: true,
        type: 'view',
        cols: 2,
        elements: [['bylawMotions', 'questions'], ['currentSpeeches', 'speechBoxes'], []]
      },
      {
        title: '',
        icon: '',
        route: '/view4',
        active: false,
        type: 'view',
        cols: 2,
        elements: [[], [], []]
      },
      {
        title: 'Ergebnisse',
        icon: 'mdi-eye',
        route: '/results',
        active: true,
        type: 'results',
        hashtag: '',
        display: 'default',
        hashtagClass: 'hashtag'
      },
      {
        title: 'Konferenz',
        icon: 'mdi-video',
        route: '/conference',
        active: false,
        type: 'conference',
        version: 'current'
      },
      {
        title: 'Dashboard',
        icon: 'mdi-cog',
        route: '/dashboard',
        active: true,
        type: 'dashboard'
      }
    ]
  }
}

function getThemeConfig (theme) {
  switch (theme) {
    case 'bdk-20':
      return {
        colors: {
          background: '#004743',
          headline: '#00ff97',
          text: '#ffffff',
          hashtag: '#ff345f',
          bar: '#ffffff',
          button: '#004743'
        },
        fonts: {
          headlineFontFamily: 'Anton',
          bodyFontFamily: 'Arvo',
          headlineTransform: 'uppercase',
          bodyTransform: 'none',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'italic',
          bodyFontStyle: 'none'
        }
      }
    case 'bdk-21':
      return {
        colors: {
          background: '#a0c864',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#f06464',
          bar: '#ffe100',
          button: '#a0c864'
        },
        fonts: {
          headlineFontFamily: 'BereitBold',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'uppercase',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
    case 'bdk-22':
      return {
        colors: {
          background: '#00694f',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#00a57e',
          bar: '#ffffff',
          button: '#00a57e'
        },
        fonts: {
          headlineFontFamily: 'BereitBold',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'none',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
    default:
      return {
        colors: {
          background: '#3c8025',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#bf0069',
          bar: '#ffd500',
          button: '#3c8025'
        },
        fonts: {
          headlineFontFamily: 'Arvo',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'uppercase',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
  }
}

export { getBaseUrl, getSupportInfo, getDefaultConfig, getThemeConfig }
