<template>
  <div class="home pa-4">
    <h1>Mit einem Meeting verbinden</h1>
    <Connect />
  </div>
</template>

<script>

import Connect from '@/components/Connect.vue'

export default {
  name: 'Home',
  components: {
    Connect
  }
}
</script>
