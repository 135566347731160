<template>
  <v-app :style="cssVars">
    <Navigation v-if="showNav" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style :content="colors" />

<script>
import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    Navigation
  },
  computed: {
    showNav () {
      return this.$route.path !== '/results' && this.$route.path !== '/conference'
    },
    theme () {
      if (this.$route.path === '/results' && this.$store.state.config.views[5].theme) {
        return this.$store.state.config.views[5].theme
      }
      return this.$store.state.config.theme
    },
    cssVars () {
      return {
        '--background-color': this.$store.state.config.colors.background,
        '--headline-color': this.$store.state.config.colors.headline,
        '--button-color': this.$store.state.config.colors.button,
        '--headline-font': this.$store.state.config.fonts.headlineFontFamily,
        '--headline-transform': this.$store.state.config.fonts.headlineTransform,
        '--headline-font-weight': this.$store.state.config.fonts.headlineFontWeight,
        '--headline-font-style': this.$store.state.config.fonts.headlineFontStyle,
        '--results-text-color': this.$store.state.config.colors.text,
        '--results-body-font': this.$store.state.config.fonts.bodyFontFamily,
        '--results-body-transform': this.$store.state.config.fonts.bodyTransform,
        '--results-body-font-weight': this.$store.state.config.fonts.bodyFontStyle,
        '--results-body-font-style': this.$store.state.config.fonts.bodyFontStyle,
        '--results-hashtag-color': this.$store.state.config.colors.hashtag,
        '--results-bar-color': this.$store.state.config.colors.bar
      }
    }
  },
  mounted () {
    const config = localStorage.getItem('config')
    if (config) {
      this.$store.commit('setConfig', JSON.parse(config))
    } else {
      this.$store.commit('setDefaultConfig')
    }
    // get support info from config
    this.$store.commit('setSupportInfo')

    // if there are url params, connect using those
    // else if there are credentials in local storage, connect using those
    const storage = localStorage.getItem('auth')
    const query = new URLSearchParams(window.location.search)

    if (query && query.get('meeting') && query.get('token')) {
      this.$store.dispatch('initiateConnection', { meetingId: query.get('meeting'), token: query.get('token') })
    } else if (storage) {
      const credentials = JSON.parse(storage)
      this.$store.dispatch('initiateConnection', { meetingId: credentials.meetingId, token: credentials.token })
    }
  }
}
</script>

<style lang="scss">
  :root {
    --body-font: 'PTSans';
    --red: #ff345f;
  }

  @font-face { font-family: 'BereitBold';
             src: url('/fonts/BereitBold-Oblique.otf')  format('otf'),
                  url('/fonts/BereitBold-Oblique.woff2')  format('woff2'),
                  url('/fonts/BereitBold-Oblique.woff')  format('woff'); }

  @font-face { font-family: 'PTSans'; src: url('/fonts/ptsans-regular.woff')  format('woff2'); }

  .v-application {
    font-family: 'PTSans', 'Roboto', sans-serif;

    .primary--text {
      color: var(--background-color);
      caret-color: var(--background-color);
    }
  }

  .v-main {
    .v-btn {
      background-color: var(--button-color) !important;
      font-weight: bold;
      text-transform: none;
    }
  }

  .red--text {
    color: #ff345f !important;
  }

  html {
    overflow-y: auto !important;
  }

  .view,
  .dashboard {
    padding: 2rem;
  }

  th {
    color: rgb(0,0,0) !important;
    border-color: rgba(0,0,0,0.2);
  }
</style>
