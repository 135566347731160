import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { getBaseUrl, getSupportInfo, getDefaultConfig, getThemeConfig } from './config'
import {
  getConferenceData,
  getFormattedTime,
  getMappedQuestions,
  getMappedCandidacies,
  getActiveSpeakers,
  getSpeakersFromBoxes,
  getCurrentDelegation,
  getMappedPolls,
  getAgenda,
  getPollType,
  getPollStatus,
  getGroupedPollResults,
  getMappedMotion,
  handleExceptions
} from './helpers'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

// Vuex Store

export default new Vuex.Store({
  state: {
    connectionStatus: 'disconnected',
    failedConnectionCounter: 0,
    voteError: null,
    exceptionCount: 0,
    support: {},
    token: null,
    meetingId: null,
    // polls
    agenda: null,
    delegates: null,
    currentPoll: null,
    polls: null,
    // speakers
    bylawMotions: null,
    activeSpeakers: null,
    openSpeakers: null,
    quotedSpeakers: null,
    conference: null,
    previousConference: null,
    // questions
    questions: null,
    candidacies: null,
    config: {
      title: 'Präsidiumsansicht',
      theme: 'bdk-22',
      colors: {
        background: '#00694f',
        headline: '#ffffff',
        text: '#ffffff',
        hashtag: '#00a57e',
        bar: '#ffffff',
        button: '#00a57e'
      },
      fonts: {
        headlineFontFamily: 'BereitBold',
        bodyFontFamily: 'PTSans',
        headlineTransform: 'none',
        bodyTransform: 'none',
        headlineFontWeight: 'normal',
        bodyFontWeight: 'normal',
        headlineFontStyle: 'none',
        bodyFontStyle: 'none'
      },
      language: '',
      views: [{}, {}, {}, {}, {}, {}]
    }
  },

  mutations: {
    setTheme (state) {
      if (state.config.theme !== 'custom') {
        const themeConfig = getThemeConfig(state.config.theme)
        state.config.colors = themeConfig.colors
        state.config.fonts = themeConfig.fonts
      }
    },
    setConfig (state, config) {
      state.config = config

      if (config.theme && config.theme !== 'custom') {
        const themeConfig = getThemeConfig(config.theme)
        state.config.colors = themeConfig.colors
        state.config.fonts = themeConfig.fonts
      }
    },

    setDefaultConfig (state) {
      state.config = getDefaultConfig()
    },

    setSupportInfo (state) {
      state.support = getSupportInfo()
    },

    setConnectionStatus (state, status) {
      state.connectionStatus = status
    },

    setFailedConnectionCounter (state, count) {
      state.failedConnectionCounter = count
    },

    setExceptionCount (state, count) {
      state.exceptionCount = count
    },

    setVoteError (state, voteData) {
      state.voteError = voteData
    },

    setAuthentication (state, props) {
      state.token = props.token
      state.meetingId = props.meetingId
    },

    // polls
    setAgenda (state, agenda) {
      state.agenda = agenda
    },

    setDelegates (state, delegates) {
      state.delegates = delegates
    },

    setCurrentPoll (state, poll) {
      state.currentPoll = poll
    },

    setPolls (state, polls) {
      state.polls = polls
    },

    // speakers
    setBylawMotions (state, motions) {
      state.bylawMotions = motions
    },

    setActiveSpeakers (state, speakers) {
      state.activeSpeakers = speakers
    },

    setOpenSpeakers (state, speakers) {
      state.openSpeakers = speakers
    },

    setQuotedSpeakers (state, speakers) {
      state.quotedSpeakers = speakers
    },

    setConferenceData (state, data) {
      if (data) {
        // Robustness: A single wrong API response won't interrupt the meeting
        const newLink = data ? data.link : null
        const newMeetingId = data ? data.meetingId : null
        const previousLink = state.previousConference ? state.previousConference.link : null
        const previousMeetingId = state.previousConference ? state.previousConference.meetingId : null
        const isConfirmed = (newLink === previousLink && newMeetingId === previousMeetingId)
        if ((!previousLink && !previousMeetingId) || isConfirmed) {
          state.conference = data
        }
      }
      state.previousConference = data
    },

    setQuestions (state, questions) {
      state.questions = questions
    },

    setCandidacies (state, candidacies) {
      state.candidacies = candidacies
    }
  },

  actions: {
    // connection
    initiateConnection (context, props) {
      context.commit('setAuthentication', props)
      context.commit('setConnectionStatus', 'connecting')
      context.commit('setFailedConnectionCounter', 0)
      context.commit('setExceptionCount', 0)
      context.dispatch('connect')
    },

    connect (context, props) {
      const meetingUrl = getBaseUrl() + context.state.meetingId + '/state.json'
      const currentPollUrl = getBaseUrl() + context.state.meetingId + '/poll/current/state.json'

      // get agenda

      axios.get(meetingUrl, {
        headers: {
          Authorization: 'Bearer ' + context.state.token
        }
      }).then(function (response) {
        context.commit('setConnectionStatus', 'connected')
        context.commit('setFailedConnectionCounter', 0)
        context.dispatch('mapData', response.data)
        setTimeout(() => {
          context.dispatch('connect')
        }, 5000)
      }).catch(function (error) {
        // eslint-disable-next-line
        console.error(error)
        if (context.state.failedConnectionCounter < 3) {
          // retry
          context.commit('setFailedConnectionCounter', context.state.failedConnectionCounter + 1)
          setTimeout(() => {
            context.dispatch('connect')
          }, 5000)
        } else {
          // abort
          context.commit('setConnectionStatus', 'disconnected')
        }
      })

      // get current poll data
      axios.get(currentPollUrl, {
        headers: {
          Authorization: 'Bearer ' + context.state.token
        }
      }).then(function (response) {
        if (response.data) {
          context.dispatch('mapCurrentPoll', response.data)
        }
      }).catch(function (error) {
        // eslint-disable-next-line
        console.error(error)
      })
    },

    // data mapping
    mapData (context, data) {
      handleExceptions(context, data._exceptions)
      const currentPollId = context.state.currentPoll ? context.state.currentPoll.id : null

      // polls
      if (data.poll) {
        context.commit('setPolls', getMappedPolls(data.poll, currentPollId))
      }

      // agenda
      if (data.agenda) {
        context.commit('setAgenda', getAgenda(data.agenda, data.poll, currentPollId))
      }

      context.dispatch('mapDelegates', data.delegation)

      // speakers
      if (data.bylaw_motions && data.bylaw_motions.list && data.bylaw_motions.list.motions) {
        context.dispatch('mapBylawMotions', data.bylaw_motions.list.motions)
      }

      if (context.state.currentPoll && data.speech) {
        const currentSpeeches = data.speech[context.state.currentPoll.id]

        context.commit('setActiveSpeakers', getActiveSpeakers(currentSpeeches))
        context.commit('setOpenSpeakers', getSpeakersFromBoxes(currentSpeeches, ['Offen', 'Redebox 2']))
        context.commit('setQuotedSpeakers', getSpeakersFromBoxes(currentSpeeches, ['Quotiert', 'Frauen', 'Redebox 1']))
        context.commit('setConferenceData', getConferenceData(currentSpeeches))

        // questions
        if (data.questionboxBe && data.questionboxBe.questionboxBe && data.questionboxBe.questionboxBe.questionBoxesAdmin) {
          const currentQuestions = data.questionboxBe.questionboxBe.questionBoxesAdmin
          context.commit('setQuestions', getMappedQuestions(currentQuestions))
        }

        if (data.candidacy) {
          const currentCandidacies = data.candidacy[context.state.currentPoll.id]
          context.commit('setCandidacies', getMappedCandidacies(currentCandidacies))
        }
      }
    },

    mapDelegates (context, delegation) {
      const currentDelegation = getCurrentDelegation(delegation)
      if (currentDelegation && currentDelegation.statistics && currentDelegation.statistics.total) {
        const data = currentDelegation.statistics.total
        const mappedDelegates = {
          total: data.cards_total,
          present: data.cards_claimed,
          male: data.cards_claimed_by_genders.male,
          female: data.cards_claimed_by_genders.female,
          diverse: data.cards_claimed_by_genders.divers,
          time: getFormattedTime(new Date())
        }
        context.commit('setDelegates', mappedDelegates)
      }
    },

    mapCurrentPoll (context, data) {
      handleExceptions(context, data._exceptions)

      if (data.poll) {
        const optionGroups = getGroupedPollResults(data.poll, data.vote, context)
        const mappedPoll = {
          question: data.poll.question ? data.poll.question : '-',
          status: data.poll.state ? getPollStatus(data.poll.state) : '-',
          submittedVotes: data.vote.count ? data.vote.count : '-',
          round: data.poll.round ? data.poll.round : '-',
          type: data.poll.type ? getPollType(data.poll.type) : '-',
          options: optionGroups,
          id: data.poll.id
        }
        context.commit('setCurrentPoll', mappedPoll)
      }
    },

    // bylaw motions

    mapBylawMotions (context, motions) {
      const mappedMotions = []
      motions.forEach((item, i) => {
        const mappedMotion = getMappedMotion(item)
        if (mappedMotion) {
          mappedMotions.push(getMappedMotion(item))
        }
      })
      context.commit('setBylawMotions', mappedMotions)
    }
  }
})
